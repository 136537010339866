(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/get-payment-message/get-payment-done-message.js') >= 0) return;  svs.modules.push('/components/sport/get-payment-message/get-payment-done-message.js');

'use strict';

const {
  Currency
} = svs.utils.format;
const {
  getGrasrotenMessageForDialogOrToaster
} = svs.sport.sport_common;
const {
  analytics
} = svs.components;
const {
  helpers
} = svs.utils.products;
const TRACKING_ACTIONS = {
  TRANSFER: 'transfer',
  CANCEL_TRANSFER: 'cancel transfer',
  MY_GAMES: 'my-games',
  OK: 'ok'
};
const getCost = amount => "<span class=\"nowrap\">".concat(Currency(amount, false), " kr</span>");
const trackEvent = _ref => {
  let {
    trackingAction,
    productName
  } = _ref;
  const trackingProductName = Array.isArray(productName) ? productName[0] : productName;
  const isMatchenOrBomben = helpers.isMatchen(trackingProductName) || helpers.isBomben(trackingProductName);
  if (!isMatchenOrBomben) {
    analytics.trackEvent({
      category: 'overlays',
      action: trackingAction,
      opt_label: trackingProductName
    });
  }
};

async function getPaymentDoneMessage(_ref2, callback) {
  let {
    amount,
    productName,
    compDesc,
    competitionSignedUp,
    tipsetBombenAmount,
    numberOfGames = 1,
    extraMessage,
    marketplaceUrl,
    isRetailer,
    transferTo,
    okAction,
    errorWagers
  } = _ref2;
  if (!amount || !productName) {
    throw new Error('Missing required properties `amount` or `productName`');
  }
  const returnObject = {
    paymentDoneDialogConfig: {
      branding: svs.components.dialog.branding.SPORT,
      icon: 'thumbs-up',
      actions: [{
        title: 'Okej'
      }]
    },
    transferDialogConfig: undefined
  };
  let message = '';
  const your = numberOfGames > 1 ? 'Dina' : 'Ditt';
  const payed = numberOfGames > 1 ? 'betalade' : 'betalat';
  const theGame = numberOfGames > 1 ? 'Spelen' : 'Spelet';
  if (okAction) {
    returnObject.paymentDoneDialogConfig.actions[0].callback = () => {
      trackEvent({
        trackingAction: TRACKING_ACTIONS.OK,
        productName
      });
      okAction();
    };
  } else {
    returnObject.paymentDoneDialogConfig.actions[0].callback = () => {
      trackEvent({
        trackingAction: TRACKING_ACTIONS.OK,
        productName
      });
      svs.components.dialog.api.close();
    };
  }
  if (competitionSignedUp) {
    message = "Du \xE4r anm\xE4ld till ".concat(compDesc, ".<br />");
  }
  const productNameArray = [].concat(productName);
  if (errorWagers && errorWagers.length > 0 && productNameArray.length > 1) {
    returnObject.paymentDoneDialogConfig.title = "Ditt spel p\xE5 ".concat(productNameArray[0], " betalades men ditt spel p\xE5 Fulltr\xE4ff gick inte igenom");
    message += "".concat(theGame, " kostade ").concat(getCost(amount), ".");
  } else if (productNameArray && productNameArray.length > 1) {
    returnObject.paymentDoneDialogConfig.title = "Dina spel p\xE5 ".concat(compDesc ? compDesc : productNameArray[0], " och ").concat(productNameArray[1], " \xE4r betalade");
    message += "".concat(theGame, " kostade ").concat(getCost(amount), ".");
  } else if (compDesc) {
    returnObject.paymentDoneDialogConfig.title = "".concat(your, " ").concat(compDesc, "-spel \xE4r ").concat(payed);
    message += "".concat(theGame, " kostade ").concat(getCost(amount), ".");
  } else if (tipsetBombenAmount) {
    returnObject.paymentDoneDialogConfig.title = "".concat(your, " spel \xE4r ").concat(payed);
    message += "".concat(productNameArray[0], " kostade ").concat(getCost(amount), " och Bomben kostade ").concat(getCost(tipsetBombenAmount), ".");
  } else {
    returnObject.paymentDoneDialogConfig.title = "".concat(your, " spel p\xE5 ").concat(productNameArray[0], " \xE4r ").concat(payed);
    message += "".concat(theGame, " kostade ").concat(getCost(amount), ".");
  }
  function showAfterPaymentMessage(transferAbortAction, grasroten) {
    if (message) {
      returnObject.paymentDoneDialogConfig.message = [{
        type: svs.components.dialog.message.TEXT,
        text: message.trim()
      }];
    }
    if (extraMessage) {
      if (!returnObject.paymentDoneDialogConfig.message) {
        returnObject.paymentDoneDialogConfig.message = [];
      }
      extraMessage.forEach(mess => {
        returnObject.paymentDoneDialogConfig.message.push({
          type: svs.components.dialog.message.TEXT,
          text: mess
        });
      });
    }
    if (transferTo && transferTo.gameName) {
      returnObject.transferDialogConfig = {
        branding: svs.components.dialog.branding.SPORT,
        icon: 'nav-my-games',
        title: transferTo.customTitle ? transferTo.customTitle : "Vill du \xF6verf\xF6ra dina rader till ".concat(transferTo.gameName, "?"),
        actions: [{
          title: 'Nej tack',
          callback: transferAbortAction
        }, {
          title: 'Överför',
          callback: () => {
            trackEvent({
              trackingAction: TRACKING_ACTIONS.TRANSFER,
              productName
            });
            transferTo.transferAction();
          }
        }]
      };
    } else if (!(grasroten && grasroten.hasSponsorship)) {
      if (okAction) {
        returnObject.paymentDoneDialogConfig.actions[0].callback = () => {
          trackEvent({
            trackingAction: TRACKING_ACTIONS.OK,
            productName
          });
          svs.components.dialog.api.close();
          okAction();
          new svs.sport.GrassrootsToaster().show();
        };
      } else {
        returnObject.paymentDoneDialogConfig.actions[0].callback = () => {
          trackEvent({
            trackingAction: TRACKING_ACTIONS.OK,
            productName
          });
          svs.components.dialog.api.close();
          new svs.sport.GrassrootsToaster().show();
        };
      }
    } else if (okAction) {
      returnObject.paymentDoneDialogConfig.actions[0].callback = () => {
        trackEvent({
          trackingAction: TRACKING_ACTIONS.OK,
          productName
        });
        svs.components.dialog.api.close();
        okAction();
      };
    } else {
      returnObject.paymentDoneDialogConfig.actions[0].callback = () => {
        trackEvent({
          trackingAction: TRACKING_ACTIONS.OK,
          productName
        });
        svs.components.dialog.api.close();
      };
    }
    returnObject.paymentDoneDialogConfig.actions.unshift({
      title: 'Mina spel',
      callback: () => {
        trackEvent({
          trackingAction: TRACKING_ACTIONS.MY_GAMES,
          productName
        });
        const isHvs = productNameArray[0].toLowerCase() === svs.utils.products.getDisplayName(38).toLowerCase();
        const isTipsenProduct = ['topptipset', 'stryktipset', 'europatipset', 'powerplay'].includes(productNameArray[0].toLowerCase());
        let urlMapping = 'myBetsHome';
        if (isTipsenProduct) {
          urlMapping = "".concat(productNameArray[0].toLowerCase(), "MyBetsHome");
        } else if (isHvs) {
          urlMapping = 'fulltraffMyBetsHome';
        }
        location.href = svs.core.urlMapping.get(urlMapping);
      }
    });
    callback(returnObject);
  }
  if (isRetailer) {
    message += '<br />Du kan nu stänga fliken.';
    returnObject.paymentDoneDialogConfig.message = [{
      type: svs.components.dialog.message.TEXT,
      text: message.trim()
    }];
    callback(returnObject);
  } else {
    const game = tipsetBombenAmount || numberOfGames > 1 && !compDesc ? 'dem' : 'det';
    message += " Du hittar ".concat(game, " ").concat(compDesc ? "i ".concat(compDesc, "-fliken och") : '', " under Mina spel");
    if (marketplaceUrl) {
      message += " och p\xE5 <a class=\"alert-text-link\" href=\"".concat(marketplaceUrl, "\">lagsidan</a>");
    }
    message += '. ';
    try {
      const grasroten = await getGrasrotenMessageForDialogOrToaster();
      let transferAbortAction;
      if (grasroten.hasSponsorship) {
        message += grasroten.thankYouMessage;
        transferAbortAction = () => {
          trackEvent({
            trackingAction: TRACKING_ACTIONS.CANCEL_TRANSFER,
            productName
          });
          transferTo.abortAction(false);
        };
      } else {
        transferAbortAction = () => {
          trackEvent({
            trackingAction: TRACKING_ACTIONS.CANCEL_TRANSFER,
            productName
          });
          transferTo.abortAction(false);
          new svs.sport.GrassrootsToaster().show();
        };
      }
      showAfterPaymentMessage(transferAbortAction, grasroten);
    } catch (_unused) {
      showAfterPaymentMessage(() => {}, undefined);
    }
  }
}
setGlobal('svs.components.sport.getPaymentDoneMessage', getPaymentDoneMessage);

 })(window);